import { useQuery } from 'urql';
import { gql } from 'urql';

const FIND_USER_BY_NAME_QUERY = gql`
  query FindUserByName($searchString: String!, $showEventOrganizer: Boolean!) {
    findUserByName(searchString: $searchString, showEventOrganizer: $showEventOrganizer) {
      id
      uuid
      email
      login
      position
      phone
      firstName
      lastName
      avatar
      confirmed
      sortNumber
      active
      surname
      source
      isIndividual
      departamentUuid
      createdAt
      updatedAt
    }
  }
`;

export const useFindUserByName = (searchString: string) => {
  const [{ data, fetching, error }] = useQuery({
    query: FIND_USER_BY_NAME_QUERY,
    variables: { searchString, showEventOrganizer: false },
    pause: !searchString,
  });

  return { users: data?.findUserByName || [], fetching, error };
};
