import { useMutation } from 'urql';
import { gql } from 'urql';

export const DELETE_TICKET_MUTATION = gql`
  mutation ($id: [Int!]) {
    deleteTickets(id: $id) {
      success
      rowsAffected
    }
  }
`;

export interface IDeleteTicketVariables {
  id: number[];
}

export interface IDeleteTicketResponse {
  deleteTickets: {
    success: boolean;
    rowsAffected: number;
  };
}

export const useFetchDeleteTicket = () => {
  const [result, executeMutation] = useMutation<IDeleteTicketResponse, IDeleteTicketVariables>(DELETE_TICKET_MUTATION);
  return { deleteTickets: executeMutation, fetching: result.fetching };
};
